type CallingCodeObject = {
  name: string;
  dialCode: string;
  emoji: string;
  code: string;
};

export const selectedCallingCodes: CallingCodeObject[] = [
  {
    name: 'Serbia',
    dialCode: '381',
    emoji: '🇷🇸',
    code: 'RS',
  },
  // {
  //   name: 'Montenegro',
  //   dialCode: '382',
  //   emoji: '🇲🇪',
  //   code: 'ME',
  // },
  // {
  //   name: 'Bosnia and Herzegovina',
  //   dialCode: '387',
  //   emoji: '🇧🇦',
  //   code: 'BA',
  // },
  // {
  //   name: 'Croatia',
  //   dialCode: '385',
  //   emoji: '🇭🇷',
  //   code: 'HR',
  // },
  // {
  //   name: 'Macedonia',
  //   dialCode: '389',
  //   emoji: '🇲🇰',
  //   code: 'MK',
  // },
  // {
  //   name: 'Slovenia',
  //   dialCode: '386',
  //   emoji: '🇸🇮',
  //   code: 'SI',
  // },
  // {
  //   name: 'Austria',
  //   dialCode: '43',
  //   emoji: '🇦🇹',
  //   code: 'AT',
  // },
  // {
  //   name: 'Germany',
  //   dialCode: '49',
  //   emoji: '🇩🇪',
  //   code: 'DE',
  // },
];
