import React from 'react';
import { css } from '@emotion/core';
import { ErrorMessage } from 'formik';

const errorDiv = css`
  color: #f64740;
  margin-top: 4px;
  font-size: 12px;
`;

interface ErrMessageProps {
  name: string;
}

export const CustomErrorMessage = ({ name }: ErrMessageProps) => (
  <ErrorMessage name={name} render={(msg) => <span css={errorDiv}>{msg}</span>} />
);
