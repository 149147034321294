import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Flex, Text } from 'rebass';

export const BasicHaircutText = (): React.ReactElement => (
  <Flex
    style={{
      padding: '16px',
      marginBottom: '8px',
      backgroundColor: 'rgb(255, 255, 118, 0.5)',
      borderRadius: '8px',
      textAlign: 'justify',
      lineHeight: '1.7',
    }}
  >
    <Text as="p" fontSize="14px">
      <AiFillInfoCircle color="#F46036" /> U usluzi <b>Šišanje</b> pripadaju frizure klasičnog tipa, vreme za njihovu izradu je 30 minuta
      (bez dodatnih usluga). To su frizure koje koriste tehnike: ravna šišanja makazama (bez tehnika geometriskog šišanja, kliznog i
      ubodnog) sa prelazima sa strane od 1.5 do 18 milimetara, korišćenjem graničnika na mašini: nula pet (1,5 mm), jedinica (3mm), dvojka
      (6mm), trojka (9mm), četvorka (12mm), petica (15mm) i šestica (18mm). U slučaju dodatne usluge Fejd šišanja sa strane, cena šišanja se
      povećava u zavisnosti od težine izrade fejda sa strane, da li je nula pet, mašinska nula ili obrijana nula (izaberite traženu uslugu).
    </Text>
  </Flex>
);

export const StyleHaircutText = (): React.ReactElement => (
  <Flex
    style={{
      padding: '16px',
      marginBottom: '8px',
      backgroundColor: 'rgb(255, 255, 118, 0.5)',
      borderRadius: '8px',
      textAlign: 'justify',
      lineHeight: '1.7',
    }}
  >
    <Text as="p" fontSize="14px">
      <AiFillInfoCircle color="#F46036" /> U usluzi <b>Stilsko šišanje</b> se pored ravnog šišanja koriste tehnike ubodnog i kliznog
      šišanja, sa tehnikama geometriskog šišanja. To su frizure: pumpadur, french crop, retro stil šišanja sa naglaskom na &apos;30,
      &apos;40, &apos;50, &apos;60, &apos;70 i &apos;80 godine prošlog veka, asimetrične frizure, muled, mod, messy i ostale varijacije
      frizura koje traže vreme za njihovu izradu. U slučaju dodatne usluge Fejd šišanja sa strane, cena stilskog šišanja se povećava u
      zavisnosti od težine izrade fejda sa strane, da li je nula pet, mašinska nula ili obrijana nula (izaberite traženu uslugu).
    </Text>
  </Flex>
);

export const BuzzCutHaircutText = (): React.ReactElement => (
  <Flex
    style={{
      padding: '16px',
      marginBottom: '8px',
      backgroundColor: 'rgb(255, 255, 118, 0.5)',
      borderRadius: '8px',
      textAlign: 'justify',
      lineHeight: '1.7',
    }}
  >
    <Text as="p" fontSize="14px">
      <AiFillInfoCircle color="#F46036" />
      Usluga <b>Mašinica (Buzz cut)</b> je šišanje mašinicom cele glave sa varijacima sa strane (0, 0.5, 1, 2, 3 - graničnici za mašinice).
      U slučaju dodatne usluge Fejd šišanja sa strane, cena šišanja se povećava u zavisnosti od težine izrade fejda sa strane, da li je nula
      pet, mašinska nula ili obrijana nula (izaberite traženu uslugu).
    </Text>
  </Flex>
);

export const MaschineHaircutText = (): React.ReactElement => (
  <Flex
    style={{
      padding: '16px',
      marginBottom: '8px',
      backgroundColor: 'rgb(255, 255, 118, 0.5)',
      borderRadius: '8px',
      textAlign: 'justify',
      lineHeight: '1.7',
    }}
  >
    <Text as="p" fontSize="14px">
      <AiFillInfoCircle color="#F46036" /> Usluga <b>Mašinica samo 1 broj</b> je šišanje samo jednim brojem cele glave, bez prelaza.
    </Text>
  </Flex>
);
