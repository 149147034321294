import React from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import moment from 'moment';
import { css } from '@emotion/core';
import { navigate } from 'gatsby';
import { Flex, Text, Button } from 'rebass';
import axios from 'axios';
import { Modal } from '../../../components/layout/Modal/Modal.component';

import { CustomErrorMessage } from '../../../components/functional/CustomErrorMessage';
import Dialog from '../../../components/functional/SweetAlert';
import { theme } from '../../../layouts/main.layout';
import { selectedCallingCodes } from '../../../utils/selectedCallingCodes';
import { Haircut } from 'types/globalTypes';
import { EXPENSIVE_TIMES, EXPENSIVE_TIME_PRICE } from '../reserve.page';

const inputStyle = {
  border: `solid 0.5px ${theme.colors.borderGray}`,
  borderRadius: '8px',
  height: '45px',
  backgroundColor: `${theme.colors.white}`,
  marginTop: '10px',
  // display: 'block',
  padding: '0px 0px 0px 10px',
  width: '100%',
};

const selectStyle = {
  border: `solid 0.5px ${theme.colors.borderGray}`,
  borderRadius: '8px',
  height: '45px',
  backgroundColor: `${theme.colors.white}`,
  marginTop: '10px',
  marginRight: '6px',
  // display: 'block',
  padding: '0px 0px 0px 10px',
  width: '20%',
};

const headingStyle = css`
  font-size: ${theme.fontSizes[6]}px;
  color: black;
  font-weight: bold;
  margin-bottom: 15px;
`;

const subTitleStyle = css`
  color: black;
  font-size: ${theme.fontSizes[3]}px;
`;

const labelTextStyle = css`
  color: ${theme.colors.borderGray};
  margin-top: 10px;
  font-size: ${theme.fontSizes[1]}px;
  text-align: justify;
`;

const buttonStyle = {
  height: '50px',
  width: 'auto',
  backgroundColor: '#F64740',
  fontWeight: 'bold',
  letterSpacing: '2px',
  cursor: 'pointer',
};

const containerStyle = css`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 14px 0 rgba(82, 82, 82, 0.2);
  padding: 50px 35px;
  margin: auto;
  width: 100%;
  @media screen and (max-width: 991px) {
    margin-top: 25%;
    width: 98%;
  }
`;

interface HaircutForm {
  name: string;
  mobileNumber: string;
  dialCode: string;
}

interface ReserveModalProps {
  show: boolean;
  onHide: () => void;
  haircut: Haircut;
  date: string;
  time: string;
  additionalHaircuts: Haircut[];
  totalPrice: number;
  totalDuration: number;
}

export const ReserveModal = ({ show, onHide, haircut, date, time, additionalHaircuts, totalPrice, totalDuration }: ReserveModalProps) => {
  const handleSubmit = async (data: HaircutForm) => {
    try {
      const scheduledAt = `${moment(date).format('YYYY-MM-DD')} ${time}`;

      const trimmedMobileNumber = data.mobileNumber.split(' ').join('');
      let parsedMobileNumber = '';

      // Parse calling code + number
      if (trimmedMobileNumber[0] === '0') {
        const arrayOfNumber = trimmedMobileNumber.split('');
        arrayOfNumber.shift();
        parsedMobileNumber = data.dialCode + arrayOfNumber.join('');
      } else {
        parsedMobileNumber = data.dialCode + data.mobileNumber;
      }

      const response = await axios.post('appointments', {
        ...data,
        mobileNumber: parsedMobileNumber,
        mainHaircut: haircut._id,
        wantedHaircutDuration: totalDuration,
        additionalHaircuts,
        totalPrice: EXPENSIVE_TIMES.includes(time) ? EXPENSIVE_TIME_PRICE : totalPrice,
        scheduledAt,
      });

      if (response) {
        Dialog.fire({
          title: 'Uspešno si rezervisao frizuru',
          html: `
          <div style="margin-top:24px;">
          <h3>Ime: <b>${data.name}</b> - <b>${data.dialCode}${data.mobileNumber}</b></h3>
          <br />
            <h3>Frizura: <b>${haircut.name}</b></h3>
            <br />
            <h3>Cena: <b>${EXPENSIVE_TIMES.includes(time) ? EXPENSIVE_TIME_PRICE : totalPrice}</b> RSD</h3>
            <br />
            <h3>Datum: <b>${moment(date).format('DD.MM.YYYY.')} u ${time}h</b><h3>
            <br />
            <h5>Dobićeš SMS potvrdu nakon verifikacije</h5>
          </div>
          `,
          icon: 'success',
          confirmButtonColor: '#358600',
        });
        onHide();
        navigate('/');
      }
    } catch (err: any) {
      if (err.key === 'CLIENT_BLOCKED') {
        Dialog.fire({
          title: 'Tvoj nalog je blokiran',
          text: 'Pozovi ili dođi u radnju kako bi odblokirao svoj nalog',
          icon: 'warning',
          confirmButtonColor: '#f8bb86',
        });
      } else if (err.key === 'APPOINTMENT_MADE_IN_WEEK') {
        Dialog.fire({
          title: 'Imaš rezervaciju u ovoj nedelji',
          text: 'Ukoliko i dalje želiš da zakažeš termin, kontaktiraj putem telefona',
          icon: 'error',
          confirmButtonColor: '#F64740',
        });
      } else if (err.key === 'APPOINTMENT_MADE_IN_WAITING') {
        Dialog.fire({
          title: 'Imaš rezervaciju koja čeka odobrenje',
          text: 'Ukoliko i dalje imaš problema, kontaktiraj putem telefona',
          icon: 'error',
          confirmButtonColor: '#F64740',
        });
      } else if (err.key === 'APPOINTMENT_WITH_SAME_TIME_EXISTS') {
        Dialog.fire({
          title: 'Termin je zauzet',
          text: 'Neko je u medjuvremenu rezervisao izabrani termin, rezerviši drugi termin',
          icon: 'error',
          confirmButtonColor: '#F64740',
        });
      } else {
        Dialog.fire({
          title: 'Došlo je do neočekivane greške, pokušaj ponovo',
          text: 'Ukoliko i dalje imaš problema, kontaktiraj putem telefona',
          icon: 'error',
          confirmButtonColor: '#F64740',
        });
      }
    }
  };

  const initialValues = {
    name: '',
    mobileNumber: '',
    dialCode: '381',
  };

  const validationSchema = object().shape({
    name: string().required('Ime je obavezno polje'),
    dialCode: string().required(''),
    mobileNumber: string()
      .matches(/^[06]\d+$/, 'Broj telefona mora krenuti sa 6 ili 06. Primer: 65123456')
      .test('phone-length', 'Mora biti ispravan broj', (value) => {
        if (!value) return true; // beacuse we have diff msg for required
        return value.startsWith('0') ? value.length >= 9 : value.length >= 8;
      })
      .required('Broj telefona je obavezno polje'),
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ isValid }) => (
          <Form>
            <div css={containerStyle}>
              <Flex flexDirection="row-reverse">
                <p style={{ cursor: 'pointer', color: 'silver', fontSize: '22px' }} onClick={() => onHide()}>
                  X
                </p>
              </Flex>
              <Flex mt={40} mx="auto" flexDirection="column" width={{ _: '80vw', xl: '80%' }} justifyContent="center">
                <Flex justifyContent="space-between" alignItems="center">
                  <Text css={headingStyle}>Reserviši termin</Text>
                </Flex>

                <Text css={subTitleStyle}>Kontakt informacije</Text>

                <Field css={inputStyle} id="name" name="name" placeholder="Ime i prezime" />
                <CustomErrorMessage name="name" />

                <Flex>
                  <Field css={selectStyle} as="select" default="381" name="dialCode">
                    {selectedCallingCodes.map((obj) => (
                      <option key={obj.code} value={obj.dialCode}>
                        {obj.emoji} {obj.dialCode}
                      </option>
                    ))}
                  </Field>
                  <Field css={inputStyle} id="mobileNumber" name="mobileNumber" placeholder="63123456" />
                </Flex>
                <CustomErrorMessage name="mobileNumber" />

                <Button css={buttonStyle} mt={4} type="submit" disabled={!isValid}>
                  Rezerviši termin
                </Button>

                <Text css={labelTextStyle} mb={4}>
                  Pritiskom na dugme Rezerviši termin, prihvatam da me SZR Muski frizer Koki može kontaktirati putem telefona ili sms-om na
                  telefon koji sam uneo.
                </Text>
              </Flex>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
